ion-content {
  --background: #f5f5f3;
}

ion-toolbar {
  --background: #84bf48;
}

.mainHeader {
  color: #fff;
  text-align: center;
  font-size: 1.3rem;
  word-wrap: normal;
  top: 50%;
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.cardTitle {
  color: #008945;
}

.userSesion {
  color: #fff;
  text-align: right;
  margin-right: 10px;
  top: 50%;
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.headerImg {
  width: 45px;
}

.container {
  max-width: 600px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 40px auto;
}

.formContainer {
  max-width: 600px;
  transform: translateY(0%);
  margin: 10px auto;
}

.jrvInput {
  text-align: center;
  font-size: 2rem;
}

.jrvInputText {
  text-align: center;
  width: 100%;
  font-size: 2rem;
  margin: 12px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.btnAction {
  margin-top: 20px;
  width: 100%;
}

.inputResult {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  --placeholder-font-weight: normal;
}
