.wrapLink {
  display: inherit;
}

.wrapedIcon {
  font-size: 22px;
}

.bgNotLogged {
  background: #f5f5f3;
  border: none;
}

.container {
  max-width: 600px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  margin: 0px auto;
}
